<template>
	<div :class="{ 'template--preview': preview }" class="content template" id="capture">
		<div class="template-header template-children__margin">
			<div class="template_create_date">
				<label>発注請日 {{ pdfDataItems.issueDate }}</label>
			</div>
			<div class="template-title">
				<label>発注請書</label>
			</div>
			<div class="header-table">
				<div class="h-table__left">
					<table>
						<tr>
							<td class="text-align__left font-size__large" colspan="2">
								<label class="suplier-name-label"
									>{{ pdfDataItems.estimateParticular.supplier_name }} 御中</label
								>
							</td>
						</tr>
						<tr>
							<td class="text-align__left" colspan="2">
								<label class="label-medium"
									>件名：{{ pdfDataItems.estimateParticular.project_name }}</label
								>
							</td>
						</tr>
						<tr>
							<td class="text-align__left" colspan="2">
								<span />
							</td>
						</tr>
						<tr>
							<td></td>
						</tr>
						<tr class="label-medium" colspan="2">
							<td class="text-align__left">下記のとおり、御注文をお請け致しました。</td>
						</tr>
						<tr v-for="n in 3" :key="n">
							<td></td>
						</tr>
						<tr class="label-medium border__bottom">
							<td class="text-align__left text-padding__bottom">合計金額：</td>
							<td class="text-align__left text-padding__bottom total-money__large">
								{{ pdfDataItems.estimateParticular.total | formatToStringNumber }}
								円（税込）
							</td>
						</tr>
					</table>
				</div>
				<div class="h-table__right h-table__margin-top">
					<table>
						<tr>
							<span />
						</tr>
						<tr class="label-medium label-bold">
							<td class="text-align__right" colspan="2">
								{{ pdfDataItems.estimateParticular.company_name }}
								<!-- サンプルデザイン事務所 -->
							</td>
						</tr>
						<tr class="label-medium">
							<td class="text-align__right" colspan="2">
								{{ pdfDataItems.estimateParticular.estimator_name }}
								<!-- 山田 花子 -->
							</td>
						</tr>
						<tr>
							<td class="text-align__right" colspan="2">
								<label class="label-medium"
									>{{ pdfDataItems.estimateParticular.postal_code }}〒</label
								>
							</td>
						</tr>
						<tr>
							<td class="text-align__right label-medium" colspan="2">
								<label class="address" for="">
									{{ pdfDataItems.estimateParticular.address }}
								</label>
							</td>
						</tr>
						<tr>
							<td class="text-align__right" colspan="2">
								<label class="label-medium">
									TEL：{{ pdfDataItems.estimateParticular.phone_number }}</label
								>
							</td>
						</tr>
						<tr>
							<td class="text-align__right" colspan="2">
								<img v-if="companySeal" class="header-seal" :src="companySeal" />
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="template-body template-children__margin">
			<div class="body-table">
				<table>
					<tr>
						<td class="none-border" v-for="n in 15" :key="n"></td>
					</tr>
					<tr>
						<th colspan="8">品目</th>
						<!-- :style="{ textAlign: 'left', paddingLeft: '10px' }" -->
						<th colspan="2">単 価</th>
						<th>数 量</th>
						<th>単位</th>
						<th colspan="2">金 額</th>
					</tr>
					<tr></tr>
					<tr v-for="(content, index) in pdfDataItems.estimateDetails" :key="index">
						<td colspan="8">
							{{ content['item_name'] }}
						</td>
						<td colspan="2" class="__text_align-right">
							{{ content.unit_price | formatToStringNumber }}
						</td>
						<td class="__text_align-right">
							{{ content['quantity'] | formatToStringNumber }}
						</td>
						<td class="__text_align-right">{{ content.unit }}</td>
						<td colspan="2" class="__text_align-right">
							{{
								Math.trunc(Number(content['unit_price']) * Number(content['quantity']))
									| formatToStringNumber
							}}
						</td>
					</tr>
				</table>
				<div class="total-table">
					<div class="comment-area">
						<div class="comment-area__title">備考</div>
						<p v-for="(comment, index) in comments" :key="index" class="comment-text">
							{{ comment }}
						</p>
					</div>
					<table class="total-table__table">
						<tr>
							<td class="none-border total-title">小計</td>
							<td class="__text_align-right">
								{{
									pdfDataItems.estimateParticular.subtotal | formatToStringNumber
								}}
							</td>
						</tr>
						<tr>
							<td class="none-border total-title">
								消費税（{{ managementMasterInfo['consumption_tax_rate'] }}%）
							</td>
							<td class="__text_align-right border-bottom-only__light">
								{{
									pdfDataItems.estimateParticular.consumption_tax
										| formatToStringNumber
								}}
							</td>
						</tr>
						<tr v-if="projectInfo.with_holding_tax == 1">
							<td class="none-border total-title">源泉徴収税</td>
							<td class="__text_align-right">
								{{
									pdfDataItems.estimateParticular.holding_tax
										| formatToStringNumber
								}}
							</td>
						</tr>
						<tr>
							<td class="none-border total-title">合計金額</td>
							<td class="__text_align-right border-bottom-only__bold label-bold">
								{{ pdfDataItems.estimateParticular.total | formatToStringNumber }}
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import TransferService from '@/services/API/transfer.service';
import CommonConst from '@/constants/CommonConst';

import { mapState } from 'vuex';
export default {
	name: 'EstimateTemplate',
	props: {
		pdfDataItems: {
			type: Object,
		},
		preview: {
			type: Boolean,
		},
	},
	data() {
		return {
			companySeal: undefined,
			comments: [],
		};
	},
	computed: {
		...mapState(['creatorInfo', 'managementMasterInfo', 'projectInfo']),
	},
	watch: {
		creatorInfo: {
			async handler(creatorInfo) {
				if (creatorInfo && creatorInfo['company_seal']) {
					let url = await this._getPreviewImgUrl(
						creatorInfo['company_seal'].key,
						CommonConst.ONE_WEEK_SECONDS
					);

					this.getBase64Image(url);
				}
			},
			deep: true,
			immediate: true,
		},

		pdfDataItems: {
			handler(newVal) {
				if (newVal.estimateParticular.comment) {
					this.comments = newVal.estimateParticular.comment.split('\n');
				}
			},
			deep: true,
			immediate: true,
		},
	},
	mounted() {
		if (this.creatorInfo && this.creatorInfo['company_seal']) {
			this.getBase64Image(this.creatorInfo['company_seal']['link']);
		}
	},
	methods: {
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: green');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},

		getBase64Image(url) {
			const img = new Image();
			img.setAttribute('crossOrigin', 'anonymous');
			img.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0);
				const dataURL = canvas.toDataURL('image/png');
				this.companySeal = dataURL;
			};
			img.src = url;
		},
	},
};
</script>

<style src="./Template.scss" lang="scss" scoped></style>
